.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

/* ::-webkit-scrollbar {
  display: none!important;*/

.MuiDataGrid-virtualScrollerContent{
  background: #fff !important;
}

.makeStyles-root-1 {
  max-height: 100vh !important;

}

.makeStyles-typo-68 {
  color: #000000 !important;
  font-weight: 700 !important;
}

.MuiDataGrid-columnHeaders{
  /* background: #0b5aa4 !important; */
  color: #fff;
}

.MuiButton-containedSecondary {
  /* font-family: 'SF UI Text' !important; */
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 12px !important;
  line-height: 33px !important;
  color: #474747 !important;
  color: #000000 !important;
  background-color: #968e9100 !important;
}

.MuiButton-contained {
  box-shadow: none !important;
  /* background-color: #e0e0e000 !important; */
}

.pending {
  color: #FFA134;
}

.invoice {
  color: #FFA134;
}

.makeStyles-contentShift-3 {
  background: #EFF7FF;
  border-radius: 30px;
  padding-left: 24% !important;
  border: 20px solid #0B5AA4;
}

.makeStyles-typo-492 {
  color: #262A41 !important
}

.makeStyles-widgetWrapper-494 {
  box-shadow: 40px 4px 4px 0.2px rgba(180, 180, 180, 0.25);
  border-radius: 20px;
}

.MuiTypography-colorTextSecondary {
  /* font-family: 'SF UI Text' !important; */
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 26px !important;
  line-height: 31px !important;
  color: #2C2B2B !important;

}

.w-70 {
  width: 70% !important;
}

.revenue {
  /* font-family: 'SF UI Text'!important; */
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 26px !important;
  line-height: 31px !important;
  color: #2C2B2B !important;
}

.makeStyles-mainChartLegentElement-63 {
  /* font-family: 'Roboto'!important; */
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 16px !important;
  text-align: center !important;
  letter-spacing: 1px !important;
  font-variant: small-caps !important;
  color: #3C3C3C !important;
}

.sidebar__menu {
  margin-top: 220px;
}

.makeStyles-toolbar-8 {
  display: none !important;
}

.search-form {
  display: flex;
  margin-left: 80px !important;
  padding-left: 60px;
}

.search-input {
  color: #6C6C6C;
  font-weight: 500;
  margin-right: 10px;
  width: 460px;
  padding: 10px;
  font-size: 12px;
  border: 1px solid #ccc;
  background: #FFFFFF;
  box-shadow: 0px 2px 2px rgba(137, 137, 137, 0.25);
  border-radius: 20px 0px 0px 20px;
}

.dropdown {
  color: #6C6C6C;
  font-weight: 500;
  width: 142px;
  margin-right: 10px;
  padding: 10px;
  font-size: 12px;
  border: 1px solid #ccc;
  background: #FFFFFF;
  box-shadow: 0px 2px 2px rgba(137, 137, 137, 0.25);
  border-radius: 0px 20px 20px 0px;
}

.notify {

  position: absolute;
  /* font-size: 25px !important; */
  right: 5px;
  top: 0;
  box-shadow: 0px 2px 2px rgba(137, 137, 137, 0.25);
  border-radius: 50%;
  padding: 14px;
  /* mix-blend-mode: hue; */
}

.MuiTypography-h5 {
  font-size: 18px !important;
  /* font-family: 'SF UI Text' !important; */
  font-style: normal !important;
  font-weight: 600 !important;
  /* font-size: 30px; */
  line-height: 36px !important;
  color: #0B5AA4;
}


.makeStyles-totalValue-85 {
  font-size: 25px !important;

}

.demo {
  font-size: 18px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 36px !important;
  color: #FFA134 !important;
}

.chartjs-size-monitor {
  background: #FFFFFF !important;
  box-shadow: 4px 4px 4px 0.2px rgba(180, 180, 180, 0.25) !important;
  border-radius: 20px !important;
}

.d-flexed {
  margin-top: 20px;
  margin-bottom: 8px;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.datefilter {
  margin: 6px;
  padding: 5px;
  background: #FFFFFF !important;
  border: 1px solid #CDCDCD !important;
  box-shadow: 0px 1px 1px rgba(83, 83, 83, 0.25) !important;
  border-radius: 10px !important;
  margin-top:-4px;
}
.datefilter >div{
  width: 400px;display: flex;
  justify-content: space-between;
}
.datefilter >div input{
border-bottom: 2px solid #111;
border-top: 2px solid transparent;
border-right: 2px solid transparent;
border-left: 2px solid transparent;

}
.datefilter >div button{
  border:  2px solid transparent;
  padding: 2px 10px ;

  border-radius: 5px;
}

.chart {
  background: #FFFFFF !important;
  box-shadow: 4px 4px 4px 0.2px rgba(180, 180, 180, 0.25) !important;
  border-radius: 20px !important;
  Padding-top: 1px;
  padding-left: 10px;
  padding-right: 10px;
}

.MuiSelect-icon {
  display: none;
}

.MuiSelect-select.MuiSelect-select {
  display: none;
}

.makeStyles-widgetWrapper-70 {
  background: #FFFFFF !important;
  box-shadow: 4px 4px 4px 0.2px rgba(180, 180, 180, 0.25) !important;
  border-radius: 40px !important;
}

.makeStyles-moreButton-78 {
  display: none !important;
}

/* .MuiInput-root{
  display: none !important;
} */
.makeStyles-paper-77 {
  border-radius: 20px !important;
}

.notification {
  background: #FFFFFF;
  box-shadow: 0px 2px 2px rgba(86, 86, 86, 0.25);
  border-radius: 20px;
  padding: 10px;
  margin-right: 20px;


}

.sidebar__menu__item__icon {
  margin-right: 9px;
  padding-top: 5px;
}

.makeStyles-fakeToolbar-4 {
  display: none;
}

.makeStyles-pageTitleContainer-67 {
  margin-top: 10px !important;
  margin-bottom: 0px !important;
}

.MuiTypography-h1 {
  font-size: 25px !important;
}

.makeStyles-widgetBody-75 {
  padding-left: 14px !important;
  padding-right: 14px !important;
  padding-bottom: 0px !important;
}

.MuiGrid-spacing-xs-4>.MuiGrid-item {
  padding: 7px !important;
}

.makeStyles-widgetHeader-72 {
  padding-top: 5px !important;
  padding-left: 15px !important;
  padding-bottom: 0px !important;
}

.makeStyles-content-2 {
  padding-top: 10px !important;

}

.makeStyles-title-81 {
  margin-bottom: 0px !important;
}

.makeStyles-totalValueContainer-84 {
  align-items: center !important;
  transform: translateY(-10px) !important;
}

.makeStyles-bodyWidgetOverflow-89 {
  overflow: initial !important;
  /* margin-bottom: -30px !important; */
}

/* .h2{
  margin-top: 7px !important;
  margin-bottom: 7px !important;
} */



/* ------------- */


#myVideo {
  position: fixed;
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
}

.content {
  position: fixed;
  top: 0;
  background: rgba(15, 15, 15, 0.5);
  color: #f1f1f1;
  width: 100%;
  height: 100vh;
  padding: 40px;
}

.loginpageLeft,
.loginpageLeft p {
  text-align: left;
  align-items: left;
  /* padding: 20px; */

}

.loginpageLeft p {
  color: #fff;
  line-height: 28px;
}

.loginpageRight {
  text-align: center;
  align-items: right;
  justify-content: right;
  padding-left: 17% !important;
  padding-top: 7%;
}

.loginformDiv {
  /* padding-top: 30px; */
  box-shadow: 5px 2px 3px 3px #5a5a5a;
  top: 20%;
  position: relative;
  border-radius: 8%;
}

.loginformDiv h3 {
  font-size: 28px;
  /* margin-left: 50px; */
  font-weight: 700;
}

/* .loginformDiv{
  height: 500px;
  width: 400px;
  
} */
.loginformDiv input {
  width: 65% !important;
  height: 40px;
  border-radius: 5px;
  color: #000000;
  font-weight: 600;
  margin-top: 11px;
  border: 2px solid transparent;
  box-shadow: 0 0 10px 0 rgb(54 54 54 / 92%);
  ;
  padding-left: 10px;
  margin-left: 2%;
}

.login_text {
  font-size: 20px;
  /*text-align: initial;*/
  /* margin-left: 70px; */
  color: #fff;
}

.login-ask {
  display: flex;
  justify-content: space-between;
  width: 75%;
  margin: auto;
  font-size: 12px;
  cursor: pointer;
  margin-left: 22%;
}

.login-ask p {
  font-size: 14px !important;
  color: #fff;
  margin-top: 5px;
}

.loginBottomText {
  font-size: 50px;
  margin-top: -50px;
  color: rgb(199, 199, 2);
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;

}

.loginflexed {
  display: flex;
  justify-content: space-between;
}

@media screen and (max-width: 767px) {

  .withMobile_drawer{
    position: relative !important;
    margin-top: 1px !important; 
    margin-left: 3px !important;
  }
  .loginformDiv {
    text-align: center;
  }

  .logo {
    text-align: center;
  }

  .loginBottomText {
    font-size: 25px;
    margin-top: -25%;

  }

  .loginpageRight {
    padding-left: 1px !important;
  }

  /* .loginformDiv{
    padding-top: 1px;
    margin-left: -70px;
  } */
  .loginpageLeft p {
    text-align: center;
    line-height: 20px;
  }

  .loginflexed {
    display: block;
  }
}

.loginbgimg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.buttn {
  /* background-color:#3387d6!important; */
  /* border-color: rgba(248, 58, 58, 0.884)!important; */
  width: 30%;
  border-radius: 8px;
  color: #fff !important;
  font-weight: 700;
  border-bottom: 1px solid #fff !important;
  border-left: transparent;
  border-top: transparent;
  border-right: transparent;
  /* border: 2px solid transparent; */
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.9);
  transition: 0.3s;
  /* margin-left: 65px !important; */
}

.scroller {
  position: absolute;
  height: 95vh;
  top: 10px;
  left: 22.5%;
  width: 75%;
  padding-right: 1%;
}

.scrollerfull {
  position: absolute;
  height: 95vh;
  width: 97%;
  top: 10px;
  left: 1%;

}
.text-capitalize{
  text-transform: capitalize !important;
}
.dashboard_card_images{
  position: absolute;
  width: 200px !important;
  height: 180px;
  bottom: 0px;
  right: 0;
}

/* .z-2
{
z-index: -9;
} */


/* restaurant responive Design  */
@media screen and (max-width: 767px) {
  .scrollerfull .TOGGLEicon {
    left: 0% !important;
z-index: 999999 !important;
  }
.MObileMenu{
  width: 75% !important;
}
  .scroller .TOGGLEicon {
    left: -30% !important;
z-index: 999999 !important;
  }
  #getReport{
    margin-top: 5px;
    /* margin-left: 50px; */
  }
.dashboard_card_images{
  position: absolute;
  width: 119px !important;
  height: 67px;
  bottom: 0px;
  right: 0;
}
.dashboard_card_number_counter{
  font-size: 28px !important;
  width: 230px !important;
}

.notify{
  position: absolute;
  /* font-size: 25px !important; */
  right: 0 !important;
  top: 0 !important; 
}
.search-inut{
  position: relative;
  margin-left: -83px;
}

.datefilter > div{
  width: 240px;
  display: block;
}
.datefilter > div input{
margin-bottom: 6px;
width: 100%;
}


}

@media screen and (min-width: 600px) {
  #getReport{
    margin-left: 50px;
  } 
}

@media screen and (min-width: 800px) {
  #getReport{
    margin-left: 5px;
  } 
}


.total_collection_dashboard_card  li {
  margin-top: 10px !important; 
}
.total_collection_dashboard_card  li p {
  margin-top: 4px !important;
}

/*collection report media wuerry   ====  report media wuery for mobile  */   

@media screen and (max-width: 767px){
  .collection_Table{
    margin-top: 63px !important;
    margin-bottom: 20px !important;
  }
  .collection_brnGrp  {
    position: relative;
    display: block;
    /* margin-top: -92px !important; */
  }
  .collection_brnGrp button{
    padding: 2px 18px;
    font-size: 10px !important;
    height: 35px !important;
  }
  .pos_brnGrp button{
    padding: 1px 18px;
    font-size: 10px !important;
    /* height: 22px !important; */
  }
  /* .pos_brnGrp{
    position: relative;
    display: block;
    margin-top: -92px !important;
  } */
  .restronTableDiv{
    margin-top: 115px !important;
  }
 
}

.stockStatusform{
  display: flex !important;
  justify-content: space-between !important;
  width: 100%;
  padding: 20px;
}
.stockStatusformbtn{
  box-shadow: 0 0 10px 0 rgba(206, 201, 201, 0.5);
  border :transparent;
  padding: 2px 8px;
  height: 30px;
  border-radius: 6px;
}
.accountStatusform{
  display: flex !important;
  justify-content: space-between !important;
  width: 100%;
  padding: 20px;
}
.accountStatusformbtn{
  box-shadow: 0 0 10px 0 rgba(206, 201, 201, 0.5);
  border :transparent;
  padding: 2px 8px;
  height: 30px;
  border-radius: 6px;
}
/*account Status form media wuerry   ====  report media wuery for mobile  */   

@media screen and (max-width: 767px){
.accountStatusform{
  display: block !important;
  padding: 35px 0px 10px 6px;
}
.accountStatusform_secondgrid{
  padding-top: 15px;
}
.d-block collectionreportbtns{
  margin-top: 0px;
}

}

@media screen and (max-width: 767px){
  .stockStatusform{
    display: block !important;
    padding: 35px 0px 10px 6px;
  }
  .stockStatusform_secondgrid{
    padding-top: 15px;
  }
  .d-block collectionreportbtns{
    margin-top: 0px;
  }
  
  }
  @media screen and (max-width: 767px){
    .stockstatement_main{
      /* display: block !important; */
  
    }
    
   
    
    }
.dashboard_card_number_counter{
  font-size: 36px ;
  width: 230px !important;
}

.scrollerfull .TOGGLEicon {

  left: 0px;
  z-index: 99;
  position: absolute;
  border-radius: 50%;
  color: "gray";
  /*background:#ffffff; */
  cursor: pointer;
  padding: 13px;
  box-shadow: 0px 2px 2px rgba(86, 86, 86, 0.25);
  top: -6px;

}
.scroller .TOGGLEicon{
  left: -31px;
  z-index: 99;
  position: absolute;
  border-radius: 50%;
  color: "gray";
  /* background: #ffffff; */
  cursor: pointer;
  padding: 9px;
  box-shadow: 0px 2px 2px rgba(86, 86, 86, 0.25);
  top: -6px;
}

.popupbg {
  position: fixed;
  top: 0;
  z-index: 111;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.popup-main {
  position: fixed;
  background-color: #305f8b;
  width: 50%;
  height: 50%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 1px solid #333;
  overflow-y: scroll;
  padding: 1rem;
}

.display-block {
  display: block;
}

.display-none {
  display: none;
}

table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
  background: transparent;
  color: #fff;

}

td,
th {
  border: 1px solid #dddddd;
  text-align: left;

}



.makeStyles-pageTitleContainer-34 {
  margin-top: 10px !important;
  margin-bottom: 5px !important;
}

.dashboardcard {

  MARGIN-LEFT: 1PX !important;

  margin: 11px !important;
  margin-top: -20px !important;

}

.dashboardsecondcard {
  margin-top: -40px !important;
}

.dashboardcard>div {
  height: 137px !important;
  width: 195px !important;

}

.dashboardsecondcard .dashboardcardimage {
  width: 110px;
  height: 70px;
  float: right;
  position: relative;
  top: -120px;
}

.dashboardsecondcard>div {
  margin-left: -30px;
  width: 356px;
}

.dashboardsecondcard>div>div {
  height: 130px !important;
  overflow: hidden;
  margin-left: 29px !important;
  width: 235px !important;

}

.dashboardcard>div h5 {
  font-size: 14px !important;
  line-height: 15px !important;
}

.dashboardcard::-webkit-scrollbar {
  display: none;
}

.dashboardsecondcard>div h5 {
  font-size: 14px !important;
  line-height: 15px !important;
}

.dashboardsecondcard::-webkit-scrollbar {
  display: none;
}

.popup-fullscreen::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.dashboardcard {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.popup-fullscreen {
  position: fixed;
  background-color: #f4f4f5;
  width: 100%;
  height: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 1px solid #333;
  overflow-y: scroll;
  /* padding: 1rem; */
}

.popup-fullscreen .MuiGrid-root {
  padding-left: 5px !important;
}

.poinvoiceform {
  display: flex;
  justify-content: space-between;
  padding: 18px;
  margin: auto;
  width: 100%;
  background: #fff;
}

.poinvoiceform input {
  margin-left: 20px !important;


}

.pendinrfq-details h4 {
  color: #fff;
}

.pendinrfq-details p {
  background: #fff;
  color: #111;
}


.balacepoupformmain {
  height: 90vh;
  width: 100%;
}

.balacepoupformleft {
  width: 30%;
  height: 100%;
}

.left-blankspace {
  height: 130px !important;
  background: rgb(252, 252, 252);
  width: 98%;
}

.balacepoupformleft ul {
  height: 120px;
  overflow: scroll;
  padding: 1px;
}

.balacepoupformleft li {
  list-style: none;


}

.balacepoupformright {
  width: 69%;
  height: 100%;
  margin-left: 20px;

}

.balacepoupformleft .checkboxes-d-flex {
  display: flex;

}

/* .d-flex {
  display: flex;
  justify-content: space-between;
} */

.mt-2 {
  margin-top: 10px !important;
}

.form-heading {
  position: absolute;
  background: #000000;
  color: #fff;
  left: 0;
  width: auto;
  padding-left: 10px;
  padding: 5px;
  border: 2px solid #333;
  box-shadow: 0 0 20px 0 rgba(145, 142, 142, 0.9);
}

.crossicon {
  color: rgb(17, 17, 17);
  /* float: right; */
  background: rgb(0, 0, 0);
  padding: 10px;
  position: absolute;
  top: 0;
  right: 0;
  margin-top: 0px;
  z-index: 111 !important;

}

.reportsSubmenu {
  position: absolute;
  background: #0b5aa4;
  padding: 20px;
  top: 30%;
  left: 100%;
  width: 200px;
}

.reportsSubmenu li {
  list-style: none;
}

.reportsSubmenu li a {
  color: #fff;
  text-decoration: none;
}

.d-none {
  display: none;
}

#selectFROMdropdown {
  margin-top: 12px;
  width: 97%;
  border: none;
}

#selectTOdropdown {
  margin-top: 20px;
  width: 97%;
  border: none;
}

.reportsselectedAcccounts {
  width: 97%;
  background-color: rgb(255, 255, 255);
  /* background: #fff; */

}

.reportheight {
  height: 120px !important;
}

.colectionreportform {
  height: 20%;
  width: 100%;
  display: flex;
}

.colectionreportform table {
  width: 43%;
}

.collectionformcheckboxdiv {
  margin: 4px;
  height: 129px !important;
  padding: 1px;
  width: 95px;
}

.collectionreportbtns button {
  display: block;
  width: 169px;
  line-height: 21px;
}

.d-block {
  display: block;
}

.makeStyles-row1Card-51 {
  /* height: 100% !important; */
  box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);
    border-radius: 9px !important;
    border: 1px solid #ffffff;
}

.MuiGrid-container {
  padding-bottom: 20px;
  justify-content: space-around;
}

.Graphdata {
  background: #fff;
  border: 1px solid #11111126;
  border-radius: 10px;
  box-shadow: 5px 10px #f7f5ff;
  margin-left: 0px !important;
  width: calc(100%) !important;
  /* margin-top: 40px !important; */
}
.gftt{
  background: #fff;
  border: 1px solid #11111126;
  border-radius: 10px;
  box-shadow: 5px 10px #f7f5ff;

}

/*CODE BY VIBHAV*/

.collection_Table td {
padding: 3px ;

}

.PartyStockLedger_2 p {

  font-size: 12px;

}


.ageing_2grid select {

  float: right;

  border: none;

  padding: 2px 8px;

  box-shadow: 0 0 10 0 rgb(158, 154, 154);

}


.ageing_buttonsGrp {

  padding: 5px 10px;

}




.collection_brnGrp button {

  width: 110px;

  border: none;

  height: 30px;
border-radius: 4px;
  font-size: 13px;

  text-transform: uppercase;

  box-shadow: 0 0 10px 0 rgba(119, 117, 117, 0.9);

}


.PartyStockLedger_2grid select,
.PartyStockLedger_2grid>div {

  float: right;

  border: none;

  padding: 2px 8px;

  box-shadow: 0 0 10 0 rgb(158, 154, 154);

}



.scrollerDiv{
  height: 25vh;
  width: 100%;
  margin: auto;
  box-shadow: 0 0 10px 0 rgba(202, 224, 236, 0.9);
  text-align: start;
  background: #fff;
  color: #111;
  margin-top: 10px;
  font-size: 9px !important;
  padding: 2px 4px !important;
}

.scrollerDiv2{
  height: 13vh;
  width: 100%;
  margin: auto;
  box-shadow: 0 0 10px 0 rgba(202, 224, 236, 0.9);
  text-align: start;
  background: #fff;
  color: #111;
  margin-top: 10px;
  font-size: 9px !important;
  padding: 2px 4px !important;

}

/* hide scrollbar but allow scrolling */

.scrollerDiv {

  -ms-overflow-style: none;
  /* for Internet Explorer, Edge */

  scrollbar-width: none;
  /* for Firefox */

  overflow-y: scroll;

}


.scrollerDiv::-webkit-scrollbar {

  display: none;
  /* for Chrome, Safari, and Opera */

}

.partystock_main {

  display: flex;

}
.allwarehousestock_main{
  display: flex;
}

.pricedstock_main{

  display: flex;
}

.saleregister_main{

  display: flex;
}

.stockstatement_main{
  display: flex;
}

.trailBalance_main {

  display: flex;

}

.AgeingAnalysis_main {

  display: flex;

}

.AgeingAnalysis_main .balacepoupformleft {

  font-size: 12px !important;
  width: 33%;

}
.AgeingAnalysis_main .balacepoupformright {
  margin-left: 20px;

}

.Gstr_main {

  display: flex;

}

.AllwareHouseStock_main {

  display: flex;

}

.general_ledger {

  display: flex;

}
.partystock_main .balacepoupformleft {
  /* margin-top: 50px; */
}

@media screen and (max-width: 767px) {
 
  

  .collectionreportbtns button {
   
    width: 150px;
    transform: translate(190px, -24px);
  
  
  }

 .stockstatusbtns button {
   
    /* width: 150px;
    transform: translate(190px, -24px); */
  
  
  }
  
  .gstrbutton{
    float: left;
    margin-bottom: 10px;
  }

  .partystock_main {

    display: block !important;

  }
  
  .partystock_main .balacepoupformleft,
  .partystock_main .balacepoupformright {

    width: 100% !important;

  }
  .partystock_main .balacepoupformleft {
    margin-top: 50px;
  }
  .partystock_main .balacepoupformright {
    margin-left: 2px;
    margin-top: -55px;
  }
  .allwarehousestock_main{
    display: block  !important;
  }
  .allwarehousestock_main  .balacepoupformleft,
  .allwarehousestock_main .balacepoupformright {

    width: 100% !important;

  }
  .allwarehousestock_main .balacepoupformleft {
    margin-top: 50px;
    height: auto;
    margin-left: 4px;
  }
  .allwarehousestock_main .balacepoupformright{
    margin-left: 4px;
    margin-top: 20px;
  }

  .stockstatement_main {

    display: block !important;

  }


  .stockstatement_main .balacepoupformleft,
  .stockstatement_main .balacepoupformright {

    width: 100% !important;

  }
  .stockstatement_main .balacepoupformleft {
    margin-top: 50px;
  }
  .stockstatement_main .balacepoupformright {
    margin-left: 2px;
    /* margin-top: -55px; */
  }

  .saleregister_main {

    display: block !important;

  }

  .saleregister_main .balacepoupformleft,
  .saleregister_main .balacepoupformright {

    width: 100% !important;

  }
  .saleregister_main .balacepoupformleft {
    margin-top: 50px;
  }

  .saleregister_main .balacepoupformright {
    margin-left: 2px;
    margin-top: 245px;
  }

  .pricedstock_main {

    display: block !important;

  }
  

  .pricedstock_main .balacepoupformleft,
  .pricedstock_main .balacepoupformright {

    width: 100% !important;

  }
  .pricedstock_main .balacepoupformleft {
    margin-top: 50px;
  }
  .pricedstock_main .balacepoupformright {
    margin-left: 2px;
    margin-top: 300px;
  }


  /* Trail Balance */

  .trailBalance_main {

    display: block;

    margin-left: 10px;

  }

  .trailBalance_main .balacepoupformleft,
  .trailBalance_main .balacepoupformright {

    width: 100% !important;

  }
  .trailBalance_main .balacepoupformleft{
    margin-top: 50px;
  }
  .trailBalance_main .balacepoupformright {

    margin-top:115px;
    margin-left: 0px;

  }

  /* Ageing Analysis  */




  .AgeingAnalysis_main {

    display: block;

    margin-left: 10px;

    font-size: 12px !important;

  }

  .AgeingAnalysis_main .balacepoupformleft,
  .AgeingAnalysis_main .balacepoupformright {

    width: 100% !important;

  }

  .AgeingAnalysis_main .balacepoupformright {

    margin-top: 100px;
    margin-left: -8px;

  }


  /* GSTR */

  .Gstr_main {
    display: block;

    margin-left: 10px;

    font-size: 12px !important;

  }

  .Gstr_main .balacepoupformleft,
  .Gstr_main .balacepoupformright {

    width: 100% !important;

  }

  .Gstr_main .balacepoupformright {
    margin-left:-4px !important;
    margin-top:50px !important;

  }

  /* AllwareHouseStock_main  */
  .AllwareHouseStock_main {

    display: block;

    margin-left: 10px;

  }

  .AllwareHouseStock_main .balacepoupformleft,
  .AllwareHouseStock_main .balacepoupformright {

    width: 100% !important;

  }

  .AllwareHouseStock_main .balacepoupformright {

    margin-top: -200px;

  }


  /* AllwareHouseStock_main  */

  .general_ledger {

    display: block;

    margin-left: 10px;

  }

  .general_ledger .balacepoupformleft,
  .general_ledger .balacepoupformright {

    width: 95% !important;

  }

  

  .general_ledger .balacepoupformright {

    margin-top: -115px;
    margin-left: 0px ;
  }

}
@media screen and (max-width: 380px) {

  .partystock_main .balacepoupformright {
    margin-left: 2px;
    margin-top: 0px;
  }
.stockstatement_main .balacepoupformright {
  margin-left: 2px;
  margin-top: 55px;
}}

.Gstr_main .balacepoupformright{
  margin-left: 10px ;
}

.report_reportname{
  font-weight: 700;
  font-size: 16px ;
  margin-left: 30px;
  font-style: italic;
}
.allfont .row{
  border: 1px solid #fff;
  font-size: 12px ;
  margin-bottom: 1px !important;
  padding: 2px;
  background:#e7e7ec;
  color: #111;
  }
  .groupSelection{
    height: 25vh;
    width: 100%;
    margin: auto;
    border: 1px solid rgb(252, 252, 252);
    text-align: start;
    overflow-y: scroll ;
    margin-top: 10px;
  }
  
  .groupSelection::before{
    content: "Group Selection";
    position: absolute;
  background: #e7e7ec;
  color: #0b5aa4;
  margin-top: -8px;
  margin-left: 5px;
  }

  .gstr_ButtonGroup{
    height: 95vh !important;
    overflow:  scroll ;
  }
  
.gstr_ButtonGroup Button{
  margin: 1px !important;
  font-size: 10px !important ;
  width: 99% !important;
  text-align: start !important
}

.table > :not(caption) > * > * {
  padding: 0px !important;
}

.Commonloader {
  left: 0;
  top: 0;
    bottom: 0;
  width: 100vw!important;
    height:1000vw!important;
    background-color: #d8e2d6c2 !important;
    position: absolute !important;
    text-align: center !important;
    z-index: 9999999;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center;
    background-image: url('./images/loader.gif');

}


/* .orderdata{
  width: calc(100%) !important;
} */

.order_mangmnt_dashbord_CardH{
  height: 130px !important;
}
.order_mangmnt_dashbord_Card2H{
  height: 200px !important;
}

@media screen and (max-width: 767px) {
  .ForMedia_restorant_1stContainer{
    margin-top: -63px !important;
  }
  .ForMedia_restorant_1stContainer h1{
    font-size: 20px !important;
  }
  .ForMedia_restorant_1stContainer button{
    font-size: 10px;
  }
  .search-form {
    margin-left: 0px !important;
    transform: scale(0.7);
}
.restorant_mobile_3grid{
  margin: 0px !important;
}

}
.debitor_Card:hover .debitor_balance_text{
text-overflow: initial;
}

.css-17jjc08-MuiDataGrid-footerContainer{
  background: #fff !important;
}
.MuiDataGrid-footerContainer{
  background: #fff !important;
}

.paraLogout{
  top: 40%;
  margin-left: 10%;
  margin-top: 20px;
  width: 50%;
  position: fixed;
    display: flex;
    padding: 40px;
    justify-content: space-between;
    text-align: center;
    background: #ffffff;
    z-index: 999;
    /* height: 49px; */
    border-radius: 6px;
    box-shadow: 0 0 20px 0 rgb(183 164 164 / 97%);
}
.scrollerfull .paraLogout{
  margin-left: 20% !important;
}

@media screen and (max-width: 500px) {
  .scrollerfull .paraLogout{
    margin-left: 0% !important;
  }
  .paraLogout{
    margin-left: -15% !important;
    width: 80% !important;
    display: inherit !important;
  }
}

/* width */
::-webkit-scrollbar {
  width: var(--scroller-width);
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey; 
  border-radius: 10px;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background-color: var(--thumb-bg-color);
  width: var(--vertical-scroller-width);
  border-radius: 30px;
}

/*sidebar scroller*/
.withMobile_drawer::-webkit-scrollbar-thumb {
  background-color: var(--sidescroll-bg-color);
  width: var(--vertical-scroller-width);
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:before {
  content: "Your Logo";
  display: block;
  width: 100%;
  height: 100%;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
}


.logoutmsg{

  color: rgb(0, 0, 0);
  font-weight: 700;
  font-size: 17px;
}
.paraLogout button{
  padding: 2px 20px;
  border:  2px solid #fff;
  background: #ff2626;
  color: #ffffff;
  border-radius: 3px;
  cursor: pointer !important;
}
.loaderbtnApply{
  height: 20px;
  transform: scale(4.5);
}

.custom-alert {
  /* display: none;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
    padding: 5px;
    margin-left: 45px;
    position: absolute;
    width: 91%;
    height: 40px;
    margin-bottom: 10px;
    color: #fff;
    font-size: 18px;
    letter-spacing: 1px;
    border-radius: 5px; */
    color: rgb(0, 0, 0);
    font-weight: 700;
    font-size: 17px;
    padding: 40px;
    top: 40%;
    margin-left: 10%;
    margin-top: 20px;
    width: 50%;
    position: fixed;
    justify-content: space-between;
    text-align:center;
    background: #fff !important;
    z-index:999;
    border-radius:7px;
    box-shadow: 0 0 20px 0 rgba(160, 154, 154, 0.9);
}

.scrollerfull .custom-alert {
  margin-left: 25% !important;
}


.custom-alert.show {
  display: block;
  opacity: 1;
}

.custom-alert.hide {
  display: none;
  opacity: 0;
}
.MuiDataGrid-virtualScroller{
  background: #FFF !important;
}
.scroller .dashboard_card_number_counter{ 
  font-size: 22px;
  
  }
  .scrollerfull .dashboard_card_number_counter{ 
    font-size: 26px;
    
    }

  .scroller .dashboard_card_images{
    width: 130px !important;
    height: 120px !important;
  }

/* for larger screen  */
@media screen and (min-width: 2000px) {
  .restronTableDiv{
    height: 580px !important;
    overflow-y: scroll;
    
    }
    .posTableDiv{
      height: 620px !important;
      overflow-y: scroll;
    }
}
@media screen and (min-width: 1400px) {
  .restronTableDiv{
    height: 480px !important;
    overflow-y: scroll;
    
    }
  }

@media screen and (min-width: 1600px) {
  
    .posTableDiv{
      height: 516px !important;
      overflow-y: scroll;
    }
    .withMobile_drawer{
      position: relative !important;
      margin-top: 1px !important; 
      margin-left: 3px !important;
    }
}
  
  @media screen and (min-width: 1800px) {
    .restronTableDiv{
      height: 480px !important;
      overflow-y: scroll;
      
      }
  .fw-bold{
    font-size: 33px !important;
  }
  .bigscreen{
    font-size: 23px !important;
  }
  .restronTableDiv{
    height: 520px !important;
    overflow-y: scroll;
    
    }
    .posTableDiv{
      height: 580px !important;
      overflow-y: scroll;
    }

  .scrollerfull .dashboard_card_images{
    width: 300px !important;
    height: 133px !important;
  }
  .scroller .dashboard_card_images{
    width: 341px !important;
    height: 166px !important;
  }
  h1{
    font-size: 36px !important;
  }
  }

  /* .active{
    color: #111 !important;
  } */

  
  .active .act{
    color: #000000 !important;
    font-family: 700;
    /* padding: 5px;
    border: 2px solid #fff;
    border-radius: 10px; */
  }

  .scroller .categoryLists{
width: 110% !important;
font-size:12px ;
background: #fff;
border-radius: 5px;
    }
    
.scrollerfull .categoryLists{
   width: 95% !important;
   margin-left: 10px !important;

    }

    button.activegrphbtn {
      background-color: rgb(255, 255, 255) !important;
      color: #111;
    }

    .chart_header{
      width: 100%;
    padding: 25px 20px;
    margin-bottom: -38px;
    }

    .activeCollectionbtn{
      background: #5cb85c !important;
      color: #ffffff;
      font-weight: 700;
    }
    .activeDashboardBtn{
      background: #5cb85c !important;
      color: #ffffff !important;
      font-weight: 700;
    }
    .scroller .collection_Table button{
      font-size: 12px;
      background: #fff;
      width: 100%;
      border: none;
      border-radius: 8px;
      height: 28px;
      box-shadow: 0 0 10px 0 rgba(206, 201, 201, 0.5);
    }
    .scrollerfull .collection_Table button{
      font-size: 12px;
      background: #fff;
      width: 86%;
      border: none;
      border-radius: 8px;
      height: 28px;
      box-shadow: 0 0 10px 0 rgba(206, 201, 201, 0.5);
    }

   
    .total_collection_scroller{
      height: 126px !important;
      
    }

    .total_collection_scroller::-webkit-scrollbar {
      display: none;

    }
    .Item_belowROL{
      height: 232px !important;
    }
    .Item_belowROL::-webkit-scrollbar {
      display: none;

    }
    .withoutscroller::-webkit-scrollbar {
      display: none;
    }

    
    @media screen and (max-width: 480px) {
      .MuiGrid-grid-xs-8 {
        margin-bottom: 20px !important;
      }
      .dashboard_card_images{
        width: 107px !important;
        height:53px !important;
      }}

    #lastyearbtn, #currentyearbtn{
      height: 20px;width: 20px;border-radius: 50%;margin-left: 46px;
    }

    .fullwidth{
      width: 100% !important;

    }

    .scroller .accountStatusform{
      padding-top: 20px;
      padding-bottom: 0px;
      padding-left: 0px;
      padding-right: 0px;
    }

    .scroller .stockStatusform{
      padding-top: 20px;
      padding-bottom: 0px;
      padding-left: 0px;
      padding-right: 0px;
    }

    .dashboard_barchart .recharts-wrapper{
      margin-left: -12px !important;
      margin-top: 13px;
    }
 
    
.restronTableDiv{
/* height: 300px; */
overflow-y: scroll;

}
.posTableDiv{
  /* height: 310px; */
  overflow-y: scroll;
}

    .coltrialreport{
      margin-top:10px !important;
    }


    @media screen and (max-width: 480px) {
      .scrollerfull .collection_Table button{
        height: 35px;
    }
  }

.fullheight{
  height: 90% !important;
}

.recharts-layer.recharts-bar-rectangle {
  border-radius: 10px !important;
}

.production_table_hideFooter .MuiDataGrid-footerContainer{
display: none !important;
}




.production .MuiDataGrid-virtualScroller::-webkit-scrollbar {
  display: none;
}

.balacepoupformmain .allwarehousestock_main .balacepoupformleft{
  width: 35%;
}

.balacepoupformmain .partystock_main .balacepoupformleft{
    width: 35%;
  }
  
.balacepoupformmain .stockstatement_main .balacepoupformleft .allfont{
    width: 35% !important;
  }


.float_right{
  position: absolute;
  right: 20px;
  cursor: pointer;
}

.sticky-header .MuiDataGrid-colCellWrapper {
  position: sticky;
  top: 0;
  z-index: 1;
}

.row.PricedStockLedger_2{
  border: 1px solid #fff;
  font-size: 12px;
  margin-bottom: 1px !important;
  padding: 10px;
  background: #e7e7ec;
  color: #111;
}

.row.Saleregister_3{
  border: 1px solid #fff;
  font-size: 12px;
  margin-bottom: 1px !important;
  padding: 1px;
  background: #e7e7ec;
  color: #111;
}

.row.Saleregister_2{
  border: 1px solid #fff;
  font-size: 12px;
  margin-bottom: 1px !important;
  padding: 10px;
  background: #e7e7ec;
  color: #111;
}
.row.Saleregister_4{
  border: 1px solid #fff;
  font-size: 12px;
  margin-bottom: 1px !important;
  padding: 1px;
  padding-top: 10px;
  padding-bottom: 10px;
  background: #e7e7ec;
  color: #111;
}


@media screen and (max-width: 700px) {
.stockwarehouse{
  width: 100%;
  padding: 4px;
}

.stockyear{

  padding: 10px;
}
}

@media screen and (min-width: 768px) {
  .stockwarehouse{
    width: 30%;
  }
  }
@media screen and (max-width: 700px)
{ 
.applyclick{
  width: 85px !important;
  }

.MuiTypography-h1{
  font-size: 17px !important;
}

.btnmonths{
  /* margin-top: -30px !important; */
}

.balanceformleft{
  padding-top:50px !important;
  padding-left: 6px !important;
}
}

.dashboardpro{
 height: auto !important;
}


@media screen and (min-width: 400px){
  .rowprofitlosswd{
width: 88% !important;
  } 
}

@media screen and (min-width: 450px){
  .rowprofitlosswd{
width: 80% !important;
  } 
}

@media screen and (min-width: 500px){
  .rowprofitlosswd{
width: 72% !important;
  } 
}

@media screen and (min-width: 560px){
  .rowprofitlosswd{
width: 100% !important;
  } 
}

@media screen and (max-width:700px) {
  .dashboarddropdown{
    width: 100% !important;
  }
}
.dashboarddropdown{
  color: #6C6C6C;
  font-weight: 500;
  padding: 10px;
  font-size: 12px;
  border: 1px solid #ccc;
  background: #FFFFFF;
  box-shadow: 0px 2px 2px rgba(137, 137, 137, 0.25);
  width: 40%; 
  margin-bottom: 14px;
}

.categorydashboardbtn{
  margin: 10px 2px;
  width: 100%;
  display: flex;
}

.accountsearch{
  padding-top: 40px;
}

.slick-prev:before, .slick-next:before {
  color: #000000 !important;
}

.special-button {
  width: 30px;
}

.special-button .button-label {
  
}
.custom-fixed-card {
  position: sticky;
  bottom: 0;

}

.restronTableDiv::-webkit-scrollbar-track {
  box-shadow: none !important;

}

.MuiDataGrid-footerContainer{
  /* display: none !important; */
}